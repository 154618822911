<template>
  <div class="evaluation" v-loading="submitLoading" element-loading-text="数据提交中" v-if="questionList && questionList[sort - 1]">
    <div class="header"></div>
    <div class="main">
      <div class="progressBox">
        <el-progress
          class="progress"
          :stroke-width="10"
          :percentage="percentage"
          :show-text="false"
          color="#73986D"
        ></el-progress>
        <p class="sortBox">
          <span class="sort">{{ sort }}</span
          ><span class="quesLen">/{{ questionList.length }}</span>
        </p>
      </div>
      <div class="scrollBox">
        <div class="moduleDesc" v-if="questionList[sort - 1].moduleDesc">
          {{ questionList[sort - 1].moduleDesc }}
        </div>
        <div
          class="moduleQues"
          v-for="(item, index) in questionList[sort - 1].questionArray"
          :key="index">
          <b class="quesInfo">
            <span v-if="questionList[sort - 1].questionArray.length > 1"
              >{{ index + 1 }}.</span
            >&nbsp;{{ item.quesInfo }}</b
          >
          <div class="ladder" v-if="item.quesInfo.includes('梯子')">
            <img src="../../assets/image/baicheng/ladder.jpg" alt="" />
          </div>
          <!-- 性别 -->
          <ul class="gender" v-if="item.answerType == 'A' && item.quesInfo.includes('性别是')">
            <li
              class="sex"
              v-for="(each, idx) in item.optionArray"
              :class="each.optionId == item.answer ? 'active' : ''"
              :key="idx"
              @click="groupRadioCheck(item, each, true)"
            >
              <img
                v-if="each.optionInfo == '男' && each.optionId == item.answer"
                src="../../assets/image/baicheng/sex_man_active.png"
                alt=""
              />
              <img
                v-if="each.optionInfo == '男' && each.optionId != item.answer"
                src="../../assets/image/baicheng/sex_man.png"
                alt=""
              />
              <img
                v-if="each.optionInfo == '女' && each.optionId == item.answer"
                src="../../assets/image/baicheng/sex_woman_active.png"
                alt=""
              />
              <img
                v-if="each.optionInfo == '女' && each.optionId != item.answer"
                src="../../assets/image/baicheng/sex_woman.png"
                alt=""
              />
              <span>{{ each.optionInfo }}</span>
            </li>
          </ul>
          <!-- 输入框 -->
          <div class="inputBox" v-if="item.answerType == 'B'">
            <!-- 年龄 -->
            <el-input
              v-if="item.quesInfo.includes('年龄是')"
              min="1"
              max="150"
              v-model="item.answer"
              type="number"
              placeholder="请输入年龄"
            ></el-input>
            <el-input
              v-else-if="item.quesInfo.includes('身高是')"
              min="20"
              max="250"
              v-model="item.answer"
              type="number"
              placeholder="请输入身高"
            ></el-input>
            <el-input
              v-else-if="item.quesInfo.includes('体重是')"
              min="10"
              max="500"
              v-model="item.answer"
              type="number"
              placeholder="请输入体重"
            ></el-input>
            <span class="unit" v-if="item.quesInfo.includes('年龄是')">岁</span>
            <span class="unit" v-else-if="item.quesInfo.includes('身高是')"
              >cm</span
            >
            <span class="unit" v-else-if="item.quesInfo.includes('体重是')"
              >kg</span
            >
          </div>
          <!-- 单选分行排列 -->
          <div
            class="radio"
            v-if="
              item.answerType == 'A2' &&
              item.quesInfo.includes('性别是') == false
            "
          >
            <ul>
              <li v-for="(each, idx) in item.optionArray" :key="idx">
                <div
                  @click="
                    groupRadioCheck(
                      item,
                      each,
                      questionList[sort - 1].questionArray.length
                    )
                  "
                  :class="each.optionId == item.answer ? 'item active' : 'item'"
                >
                  <p>{{ each.optionInfo }}</p>
                </div>
              </li>
            </ul>
          </div>
          <!-- 单选一行排列 -->
          <div
            class="radioRow"
            v-if="
              item.answerType == 'A' &&
              item.quesInfo.includes('性别是') == false
            "
          >
            <ul>
              <li
                v-for="(each, idx) in item.optionArray"
                :key="idx"
                :style="{ width: 100 / item.optionArray.length + '%' }"
                :class="each.optionId == item.answer ? 'item active' : 'item'"
                @click="
                  groupRadioCheck(
                    item,
                    each,
                    questionList[sort - 1].questionArray.length
                  )
                "
              >
                <div>
                  <p>{{ each.optionInfo }}</p>
                </div>
              </li>
            </ul>
          </div>
          <!-- 多选 -->
          <div class="checkbox" v-if="item.answerType == 'C'">
            <ul>
              <li
                v-for="(each, idx) in item.optionArray"
                :class="each.checked ? 'active' : ''"
                :key="idx"
              >
                <p>
                  <el-checkbox
                    v-model="each.checked"
                    @change="checkboxCheck($event, item, each)"
                    >{{ each.optionInfo }}</el-checkbox
                  >
                </p>
              </li>
            </ul>
          </div>
          <!-- 16宫格 -->
          <div class="grid" v-if="item.answerType == 'B2'">
            <!-- <el-row :gutter="5" style="text-align: center">
              <el-col
                class="colItem"
                :span="6"
                v-for="(each, idx) in gridOptions"
                :key="idx"
                ><div
                  :class="each == item.answer ? 'active' : 'bg-purple'"
                  @click="gridCheck(item, each)"
                ></div
              ></el-col>
            </el-row> -->
            <div class="blackboard">黑板</div>
            <ul class="topSite">
              <li>最左边</li>
              <li>中间靠左</li>
              <li>中间</li>
              <li>中间靠右</li>
              <li>最右边</li>
            </ul>
            <div class="mainSite">
              <ul class="leftSite">
                <li>
                  <span>第一排</span>
                </li>
                <li>
                  <span>靠前排</span>
                </li>
                <li>
                  <span>中间靠前</span>
                </li>
                <li>
                  <span>中间靠后</span>
                </li>
                <li>
                  <span>靠后排</span>
                </li>
                <li>
                  <span>最后一排</span>
                </li>
              </ul>
              <ul class="siteList">
                <li
                  v-for="(each, idx) in gridOptions"
                  :key="idx"
                  :class="each == item.answer ? 'active' : ''"
                  @click="gridCheck(item, each)"
                ></li>
              </ul>
            </div>
          </div>
          <!-- 选择框 -->
          <div class="selectBox" v-if="item.answerType == 'B1'">
            <el-select
              v-model="item.hour"
              @change="getHour($event, item)"
              placeholder="请选择"
              size="small"
            >
              <el-option
                v-for="item in hourOptions"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option> </el-select
            ><span>小时</span>
            <el-select
              v-model="item.minute"
              @change="getMinute($event, item)"
              placeholder="请选择"
              size="small"
            >
              <el-option
                v-for="item in minOptions"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option> </el-select
            ><span>分钟</span>
          </div>
          <!-- 图片 -->
          <div class="imgBox" v-if="item.answerType == 'A3'">
            <ul>
              <li
                v-for="(each, idx) in item.optionArray"
                :class="each.optionId == item.answer ? 'active' : ''"
                :key="idx"
                @click="groupRadioCheck(item, each)"
              >
                <img :src="each.optionInfo" alt="" />
              </li>
            </ul>
          </div>
          <!-- 评分 -->
          <div class="score" v-if="item.answerType == 'A1'">
            <ul>
              <li
                v-for="(each, idx) in item.optionArray"
                :class="each.optionId == item.answer ? 'active' : ''"
                :key="idx"
                @click="
                  groupRadioCheck(
                    item,
                    each,
                    questionList[sort - 1].questionArray.length
                  )
                "
              >
                <span>{{ each.optionInfo }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="btns">
        <span class="prev" @click="prev" v-if="sort > 1">上一题</span>
        <span class="next" @click="next" v-if="sort != questionList.length"
          >下一题</span
        >
        <span v-if="sort == questionList.length" class="submit" @click="submit"
          >提交</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { getQuestionObjectiveArray, saveObjectiveAns } from '@/api/index';
export default {
  data() {
    return {
      submitLoading:false,
      questionList: [],
      sort: 1,
      answerList: [],
      startTime: 0, //起始时间
      endTime: 0, //结束时间
      hourOptions: [0, 1, 2, 3, 4, 5, 6],
      minOptions: [0, 10, 20, 30, 40, 50],
      gridOptions: [
        1, 7, 13, 19, 25, 2, 8, 14, 20, 26, 3, 9, 15, 21, 27, 4, 10, 16, 22, 28,
        5, 11, 17, 23, 29, 6, 12, 18, 24, 30,
      ],
    };
  },
  computed: {
    percentage() {
      return parseInt((this.sort / this.questionList.length) * 100);
    },
  },
  methods: {
    //上一题
    prev() {
      if (this.sort <= 1) {
        return false;
      }
      this.sort--;
    },
    //下一题
    next() {
      if (this.sort >= this.questionList.length) {
        return false;
      }
      let noAnswer = this.questionList[this.sort - 1].questionArray.filter(
        (item) => item.answer == null || item.answer == ''
      );
      if (noAnswer.length > 0) {
        this.$message({
          message: '请完成当前问卷!',
          type: 'warning',
        });
      } else {
        this.sort++;
        document.querySelector('.scrollBox').scrollTop = 0;
      }
    },
    getHour(val, item) {
      if (!item.minute) {
        item.minute = 0;
      }
      item.hour = val;
      this.$forceUpdate();
      item.answer = item.hour + '小时' + item.minute + '分钟';
    },
    getMinute(val, item) {
      if (!item.hour) {
        item.hour = 0;
      }
      item.minute = val;
      this.$forceUpdate();
      item.answer = item.hour + '小时' + item.minute + '分钟';
    },
    //获取问题
    getQuestion() {
      getQuestionObjectiveArray()
        .then((res) => {
          if (res.code === 0) {
            this.questionList = res.data.list;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //单选
    groupRadioCheck(item, each) {
      item.answer = each.optionId;
      item.optionId = each.optionId;
      item.optionScore = each.optionScore;
      this.$forceUpdate();
      // if (quesLen == 1) {
      //   this.next();
      // }
    },
    //多选
    checkboxCheck(event, item, each) {
      let mutexArr = ['无人照顾', '以上都没有', '没有参加'];
      if (each.checked && mutexArr.includes(each.optionInfo)) {
        item.optionArray.forEach((option) => {
          if (option.optionInfo != each.optionInfo) {
            option.checked = false;
          }
        });
        this.$forceUpdate();
      } else if (
        each.checked &&
        !mutexArr.includes(each.optionInfo) &&
        mutexArr.includes(
          item.optionArray[item.optionArray.length - 1].optionInfo
        ) &&
        item.optionArray[item.optionArray.length - 1].checked
      ) {
        item.optionArray[item.optionArray.length - 1].checked = false;
        this.$forceUpdate();
      } else {
        this.$forceUpdate();
      }
      item.answerArr = [];
      item.optionIdArr = [];
      item.optionScoreArr = [];
      item.optionArray.forEach((option) => {
        if (option.checked) {
          item.answerArr.push(option.optionId);
          item.optionIdArr.push(option.optionId);
          item.optionScoreArr.push(option.optionScore);
        }
      });
      item.answer = item.answerArr.join(',');
      item.optionId = item.optionIdArr.join(',');
      item.optionScore = item.optionScoreArr.join(',');
    },
    // 宫格选择
    gridCheck(item, each) {
      item.answer = each;
      this.$forceUpdate();
    },
    // 提交
    submit() {
      let noAnswer = this.questionList[this.sort - 1].questionArray.filter(
        (item) => item.answer == null || item.answer == ''
      );
      if (noAnswer.length > 0) {
        this.$message({
          message: '请完成当前问卷!',
          type: 'warning',
        });
        return false;
      }
      this.submitLoading = true;
      this.endTime = this.$util.getTimestamp();
      this.answerList = [];
      this.questionList.forEach((item) => {
        item.questionArray.forEach((each) => {
          if (each.answer) {
            this.answerList.push({
              fmQuesObjectiveId: each.fmQuesObjectiveId,
              moduleId: each.moduleId,
              answerType: each.answerType,
              answer: each.answer,
              optionId: each.optionId !== '' ? each.optionId : '',
              optionScore: each.optionScore !== ''  ? each.optionScore : '',
            });
          }
        });
      });
      let params = {
        fmUserId: this.$util.getStorage('fmUserId'), //用户ID
        totalTime: this.endTime - this.startTime,
        myAns: this.answerList,
        quesNum:'0-2'
      };
      saveObjectiveAns(params)
        .then((res) => {
          if (res.code === 0) {
            sessionStorage.setItem(
              'fmProductQuestionId',
              res.data.fmProductQuestionId
            );
            //小题编号
            sessionStorage.setItem('quesNum', res.data.quesNum);
            //是否完成
            sessionStorage.setItem('isFinished', res.data.isFinished);
            if(res.data.isFinished == 1) {
              this.$router.replace({
                path: '/finish',
                query: {
                  isFinished: res.data.isFinished,
                },
              });
            } else {
              if(res.data.quesNum == '0-1') {
                this.$router.replace('/ques');
              } else if(res.data.quesNum == '0-2') {
                this.$router.replace('/evaluation');
              } else {
                this.$router.replace('/monitor');
              }
            }
            this.submitLoading = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.submitLoading = false;
        });
    },
  },
  created() {
    this.getQuestion();
  },
  mounted() {
    this.startTime = this.$util.getTimestamp();
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1900px) {
  .evaluation {
    font-family: 'SYHeiNormal';
    height: 100vh;
    background: #efefef;
    .header {
      height: 5vh;
    }
    .main {
      width: 960px;
      height: 80vh;
      background: #ffffff;
      border-radius: 16px;
      padding: 0 32px;
      margin: 0 auto;
      .progressBox {
        width: 900px;
        margin: 0 auto;
        height: 10vh;
        display: flex;
        padding: 20px 0;
        margin-bottom: 40px;
        .progress {
          width: 832px;
          margin-right: 30px;
          padding-top: 10px;
        }
        .sortBox {
          text-align: center;
          display: inline-block;
          .sort {
            color: #73986d;
            font-size: 26px;
          }
          .quesLen {
            font-size: 14px;
          }
        }
      }
      .scrollBox {
        padding-right: 20px;
        overflow-y: scroll;
        height: calc(100% - 140px);
        .moduleDesc {
          // color: #666666;
          line-height: 22px;
          font-size: 17px;
          font-weight: 550;
          margin-bottom: 32px;
          white-space: pre-line;
        }
        .moduleQues {
          margin-bottom: 28px;
          .quesInfo {
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 16px;
            display: block;
            font-weight: 400;
          }
          .ladder {
            text-align: center;
            img {
              width: 300px;
              height: 212px;
            }
          }
          .gender {
            .sex {
              width: 166px;
              height: 34px;
              line-height: 34px;
              background: #eef3ee;
              border-radius: 37px;
              color: #73986d;
              background: #eef3ee;
              display: inline-block;
              text-align: center;
              font-size: 13px;
              margin-right: 16px;
              cursor: pointer;
              img {
                width: 21px;
                height: 21px;
                margin-top: 6px;
                margin-right: 5px;
              }
            }
            .active {
              color: #ffffff;
              background: #73986d;
            }
          }
          .inputBox {
            ::v-deep .el-input {
              width: 230px;
            }
            ::v-deep input[type='number'] {
              border-radius: 17px;
              text-align: center;
            }
            .unit {
              display: inline-block;
              color: #666666;
              font-size: 13px;
              margin-left: 12px;
            }
          }
          .radio {
            font-size: 13px;
            li {
              .item {
                min-height: 34px;
                padding: 8px;
                line-height: 17px;
                margin-bottom: 8px;
                color: #73986d;
                background: #edf3ed;
                cursor: pointer;
                text-align: center;
                border-radius: 17px;
              }
              .active {
                color: #ffffff;
                background: #73986d;
              }
            }
          }
          .radioRow {
            font-size: 13px;
            ul {
              display: flex;
              li {
                color: #73986d;
                background: #edf3ed;
                margin: 0 8px;
                border-radius: 17px;
                cursor: pointer;
                min-height: 34px;
                padding: 8px;
                line-height: 17px;
                text-align: center;
                div {
                  height: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  flex-direction: column;
                }
              }
              .active {
                color: #ffffff;
                background: #73986d;
              }
            }
          }
          .checkbox {
            font-size: 13px;
            li {
              min-height: 34px;
              padding: 8px;
              line-height: 17px;
              margin-bottom: 8px;
              border-radius: 17px;
              margin: 0 auto 10px auto;
              color: #73986d;
              background: #edf3ed;
              cursor: pointer;
              text-align: center;
            }
            ::v-deep .el-checkbox__label {
              width: 820px;
              color: #73986d;
              display: inline-grid;
              white-space: pre-line;
              word-wrap: break-word;
              overflow: hidden;
              line-height: 17px;
              font-size: 13px;
            }
            .active {
              color: #ffffff;
              background: #73986d;
              ::v-deep .el-checkbox__label {
                color: #ffffff;
              }
            }
          }
          // .grid {
          //   width: 100%;
          //   margin: 0 auto;
          //   overflow: hidden;
          //   .colItem {
          //     cursor: pointer;
          //     .bg-purple {
          //       height: 34px;
          //       margin-bottom: 5px;
          //       background: #edf3ed;
          //     }
          //     .active {
          //       height: 34px;
          //       margin-bottom: 5px;
          //       background: #689a6a;
          //     }
          //   }
          // }
          .grid {
            width: 650px;
            margin: 0 auto;
            font-size: 14px;
            .blackboard {
              width: 600px;
              height: 25px;
              line-height: 25px;
              text-align: center;
              border-radius: 25px;
              background: #689a6a;
              color: #ffffff;
              margin-left: 40px;
            }
            .topSite {
              width: 600px;
              height: 30px;
              line-height: 28px;
              background: #ecf3ec;
              color: #689a6a;
              border: 1px dashed #689a6a;
              border-radius: 40px;
              display: flex;
              margin-top: 10px;
              margin-bottom: 20px;
              margin-left: 40px;
              li {
                width: 20%;
                margin-right: 10px;
                text-align: center;
                display: inline-block;
              }
            }
            .mainSite {
              width: 650px;
              display: flex;
              .leftSite {
                text-align: center;
                border-radius: 30px;
                background: #ecf3ec;
                color: #689a6a;
                margin-right: 10px;
                font-size: 12px;
                border: 1px dashed #689a6a;
                li {
                  width: 30px;
                  height: 60px;
                  padding: 0 5px;
                  line-height: 14px;
                  margin-bottom: 11px;
                  display: table;
                  span {
                    display: table-cell;
                    vertical-align: middle;
                  }
                }
              }
              .siteList {
                width: 610px;
                li {
                  width: 110px;
                  margin: 0 10px 10px 0;
                  height: 60px;
                  display: inline-block;
                  background: #ecf3ec;
                  border: 1px solid #689a6a;
                  cursor: pointer;
                }
                .active {
                  background: #689a6a;
                }
              }
            }
          }
          .selectBox {
            ::v-deep .el-select {
              width: 85px;
            }
            ::v-deep .el-select .el-input__inner {
              border-radius: 17px;
            }
            span {
              display: inline-block;
              color: #666666;
              font-size: 13px;
              margin-left: 12px;
              margin-right: 32px;
            }
          }
          .imgBox {
            ul {
              li {
                width: 210px;
                height: 118px;
                background: #f7f9f7;
                margin: 4px;
                display: inline-block;
                cursor: pointer;
                img {
                  width: 100%;
                  height: 100%;
                }
              }
              .active {
                background: #dee8de;
              }
            }
          }
          .score {
            li {
              display: inline-block;
              cursor: pointer;
              margin: 8px 24px 8px 0;
              // margin-right: 24px;
              text-align: center;
              vertical-align: top;
              span {
                width: 40px;
                height: 40px;
                font-size: 16px;
                line-height: 40px;
                text-align: center;
                color: #689a6a;
                border-radius: 100%;
                background: #eef3ee;
                display: inline-block;
              }
            }
            .active {
              span {
                color: #ffffff;
                background: #689a6a;
              }
              p {
                color: #689a6a;
              }
            }
          }
        }
      }
    }
    .bottom {
      height: 15vh;
      margin: 0 auto;
      overflow: hidden;
      text-align: center;
      .btns {
        font-size: 13px;
        margin-top: 30px;
        .prev,
        .next,
        .submit {
          width: 114px;
          height: 34px;
          line-height: 34px;
          background: #73986d;
          border-radius: 17px;
          display: inline-block;
          cursor: pointer;
        }
        .prev {
          color: #73986d;
          background: #ffffff;
          margin-right: 32px;
        }
        .next,
        .submit {
          color: #ffffff;
          background: #73986d;
        }
      }
    }
  }
}

@media screen and (min-width: 1900px) {
  .evaluation {
    font-family: 'SYHeiNormal';
    height: 100vh;
    background: #efefef;
    .header {
      height: 5vh;
    }
    .main {
      width: 1248px;
      height: 80vh;
      background: #ffffff;
      border-radius: 21px;
      padding: 0 41px;
      margin: 0 auto;
      .progressBox {
        width: 1170px;
        margin: 0 auto;
        height: 10vh;
        display: flex;
        padding: 26px 0;
        margin-bottom: 52px;
        .progress {
          width: 1082px;
          margin-right: 39px;
          padding-top: 13px;
        }
        .sortBox {
          text-align: center;
          display: inline-block;
          .sort {
            color: #73986d;
            font-size: 34px;
          }
          .quesLen {
            font-size: 18px;
          }
        }
      }
      .scrollBox {
        padding-right: 26px;
        overflow-y: scroll;
        height: calc(100% - 180px);
        .moduleDesc {
          // color: #666666;
          line-height: 29px;
          font-size: 22px;
          font-weight: 550;
          margin-bottom: 41px;
          white-space: pre-line;
        }
        .moduleQues {
          margin-bottom: 36px;
          .quesInfo {
            font-size: 21px;
            line-height: 26px;
            margin-bottom: 21px;
            display: block;
            font-weight: 400;
          }
          .ladder {
            text-align: center;
            img {
              width: 390px;
              height: 275px;
            }
          }
          .gender {
            .sex {
              width: 216px;
              height: 44px;
              line-height: 44px;
              background: #eef3ee;
              border-radius: 48px;
              color: #73986d;
              background: #eef3ee;
              display: inline-block;
              text-align: center;
              font-size: 17px;
              margin-right: 21px;
              cursor: pointer;
              img {
                width: 27px;
                height: 27px;
                margin-top: 8px;
                margin-right: 6px;
              }
            }
            .active {
              color: #ffffff;
              background: #73986d;
            }
          }
          .inputBox {
            ::v-deep .el-input {
              width: 299px;
            }
            ::v-deep input[type='number'] {
              border-radius: 22px;
              text-align: center;
            }
            .unit {
              display: inline-block;
              color: #666666;
              font-size: 17px;
              margin-left: 15px;
            }
          }
          .radio {
            font-size: 17px;
            li {
              .item {
                min-height: 44px;
                padding: 10px;
                line-height: 22px;
                margin-bottom: 10px;
                color: #73986d;
                background: #edf3ed;
                cursor: pointer;
                text-align: center;
                border-radius: 22px;
              }
              .active {
                color: #ffffff;
                background: #73986d;
              }
            }
          }
          .radioRow {
            font-size: 17px;
            ul {
              display: flex;
              li {
                color: #73986d;
                background: #edf3ed;
                border-radius: 22px;
                cursor: pointer;
                min-height: 44px;
                padding: 10px;
                line-height: 22px;
                text-align: center;
                display: inline-block;
                margin: 0 10px;
                div {
                  height: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  flex-direction: column;
                }
              }
              .active {
                color: #ffffff;
                background: #73986d;
              }
            }
          }
          .checkbox {
            font-size: 17px;
            li {
              min-height: 44px;
              padding: 10px;
              line-height: 22px;
              margin-bottom: 10px;
              border-radius: 22px;
              margin: 0 auto 13px auto;
              color: #73986d;
              background: #edf3ed;
              cursor: pointer;
              text-align: center;
            }
            ::v-deep .el-checkbox__label {
              width: 1066px;
              color: #73986d;
              display: inline-grid;
              white-space: pre-line;
              word-wrap: break-word;
              overflow: hidden;
              line-height: 22px;
              font-size: 17px;
            }
            .active {
              color: #ffffff;
              background: #73986d;
              ::v-deep .el-checkbox__label {
                color: #ffffff;
              }
            }
          }
          // .grid {
          //   width: 100%;
          //   margin: 0 auto;
          //   overflow: hidden;
          //   .colItem {
          //     cursor: pointer;
          //     .bg-purple {
          //       height: 44px;
          //       margin-bottom: 7px;
          //       background: #edf3ed;
          //     }
          //     .active {
          //       height: 44px;
          //       margin-bottom: 7px;
          //       background: #689a6a;
          //     }
          //   }
          // }
          .grid {
            width: 845px;
            margin: 0 auto;
            font-size: 18px;
            .blackboard {
              width: 780px;
              height: 32px;
              line-height: 32px;
              text-align: center;
              border-radius: 32px;
              background: #689a6a;
              color: #ffffff;
              margin-left: 52px;
            }
            .topSite {
              width: 780px;
              height: 39px;
              line-height: 36px;
              background: #ecf3ec;
              color: #689a6a;
              border: 1px dashed #689a6a;
              border-radius: 52px;
              display: flex;
              margin-top: 13px;
              margin-bottom: 26px;
              margin-left: 52px;
              li {
                width: 20%;
                margin-right: 13px;
                text-align: center;
                display: inline-block;
              }
            }
            .mainSite {
              width: 845px;
              display: flex;
              .leftSite {
                text-align: center;
                border-radius: 39px;
                background: #ecf3ec;
                color: #689a6a;
                margin-right: 13px;
                font-size: 15px;
                border: 1px dashed #689a6a;
                li {
                  width: 39px;
                  height: 78px;
                  padding: 0 7px;
                  line-height: 18px;
                  margin-bottom: 14px;
                  display: table;
                  span {
                    display: table-cell;
                    vertical-align: middle;
                  }
                }
              }
              .siteList {
                width: 793px;
                li {
                  width: 143px;
                  margin: 0 13px 13px 0;
                  height: 78px;
                  display: inline-block;
                  background: #ecf3ec;
                  border: 1px solid #689a6a;
                  cursor: pointer;
                }
                .active {
                  background: #689a6a;
                }
              }
            }
          }
          .selectBox {
            ::v-deep .el-select {
              width: 110px;
            }
            ::v-deep .el-select .el-input__inner {
              border-radius: 22px;
            }
            span {
              display: inline-block;
              color: #666666;
              font-size: 17px;
              margin-left: 16px;
              margin-right: 41px;
            }
          }
          .imgBox {
            ul {
              li {
                width: 273px;
                height: 153px;
                background: #f7f9f7;
                margin: 5px;
                display: inline-block;
                cursor: pointer;
                img {
                  width: 100%;
                  height: 100%;
                }
              }
              .active {
                background: #dee8de;
              }
            }
          }
          .score {
            li {
              display: inline-block;
              cursor: pointer;
              margin: 10px 31px 10px 0;
              text-align: center;
              vertical-align: top;
              span {
                width: 52px;
                height: 52px;
                font-size: 21px;
                line-height: 52px;
                text-align: center;
                color: #689a6a;
                border-radius: 100%;
                background: #eef3ee;
                display: inline-block;
              }
            }
            .active {
              span {
                color: #ffffff;
                background: #689a6a;
              }
              p {
                color: #689a6a;
              }
            }
          }
        }
      }
    }
    .bottom {
      height: 15vh;
      margin: 0 auto;
      overflow: hidden;
      text-align: center;
      .btns {
        font-size: 17px;
        margin-top: 39px;
        .prev,
        .next,
        .submit {
          width: 148px;
          height: 44px;
          line-height: 44px;
          background: #73986d;
          border-radius: 22px;
          display: inline-block;
          cursor: pointer;
        }
        .prev {
          color: #73986d;
          background: #ffffff;
          margin-right: 41px;
        }
        .next,
        .submit {
          color: #ffffff;
          background: #73986d;
        }
      }
    }
  }
}
</style>
